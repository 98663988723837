<template>
  <div class="page popup background">
    <div class="popup-wrapper">
      <div class="popup-inner complete">
        <div class="content">
          <h1>Congratulations!</h1>
          <p class="font-light">You have successfully completed the <span class="font-semibold">HCM interactive experience</span>.</p>
          <p class="font-semibold">Please take a moment to share your rating.</p>
          <div class="rating">
            <div
              v-for="index in 5"
              :key="index"
              :class="[ 'star', { active: index <= rating } ]"
              @click="rating = index"
            ></div>
          </div>
          <div class="logo logo-white"></div>

          <div
            class="button button-prompt font-extrabold font-size-28 line-height-10"
            :class="[ { inactive: rating < 0 } ]"
            @click.prevent="onShowCertificate"
          >View certificate</div>
        </div>
      </div>

      <div
        class="home"
        @click.prevent="onHome"
      ></div>
    </div>

    <div v-if="prompt" class="popup-wrapper">
      <div class="popup-inner prompt">
        <div class="content">
          <p class="font-light">Enter name</p>
          <input v-model="name" class="font-light" type="text">
          <div
            class="button button-prompt font-extrabold font-size-28 line-height-10"
            :class="[ { inactive: rating < 0 } ]"
            @click.prevent="onGenerate"
          >Generate</div>
        </div>
        <div class="close" @click.prevent="prompt = false"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageComplete',
  data() {
    return {
      rating: -1,
      prompt: false,
      name: '',
      score: 0,
    }
  },
  methods: {
    onClose() {
      this.$emit('action', { type: 'close-popup', data: null });
    },
    onShowCertificate() {
      if (this.rating < 0) return;
      this.prompt = true;
    },
    onGenerate() {
      this.$emit('action', { type: 'generate', data: { rating: this.rating, name: this.name, score: this.score } });
    },
    onHome() {
      this.$emit('action', { type: 'set-page', data: 'PageSplash' });
    },
  },
  mounted() {
    let phases = window.sessionStorage.getItem('CLARA_PHASES');
    if (phases) {
      this.score = 0;
      phases = JSON.parse(phases);
      phases.forEach(item => this.score += item.score);
    }
  },
}
</script>
