<template>
  <div v-if="config" id="app">
    <component 
      :is="page" 
      :config="config" 
      :phases="phases" 
      :video="video" 
      @action="onAction"
    ></component>
  </div>
</template>

<script>
import PageComplete from './components/PageComplete';
import PageFeedback from './components/PageFeedback';
import PageGoals from './components/PageGoals';
import PageMenu from './components/PageMenu';
import PageSplash from './components/PageSplash';
import PageVideo from './components/PageVideo';

export default {
  name: 'App',
  components: {
    PageComplete,
    PageFeedback,
    PageGoals,
    PageMenu,
    PageSplash,
    PageVideo,
  },
  data() {
    return {
      page: 'PageSplash',
      config: null,
      phases: [],
      video: null,

      actionHandlers: {
        'set-page': this.setPage,
        'navigate': this.navigate,
        'play-video': this.playVideo,
        'close-video': this.closeVideo,
        'close-popup': this.closePopup,
        'generate': this.generateCert,
        'submit-feedback': this.submitFeedback,
        'track-event': this.trackEvent,
      },
    }
  },
  methods: {
    getConfig() {
      fetch('static/config.json')
        .then(response => response.json())
        .then(data => this.initConfig(data));
    },
    initConfig(data) {
      this.config = data;
      this.initData();
    },
    initData() {
      let page = 'PageSplash';

      const phases = window.sessionStorage.getItem('CLARA_PHASES');
      if (phases) {
        this.phases = JSON.parse(phases);
        page = 'PageMenu';
      } else {
        this.resetPhases();
      }

      const phaseData = window.sessionStorage.getItem('CLARA_PHASE_PASS');
      if (phaseData) {
        window.sessionStorage.removeItem('CLARA_PHASE_PASS');
        this.updateScores(JSON.parse(phaseData));
        page = 'PageMenu';
      }

      const complete = this.isComplete();
      const feedback = window.sessionStorage.getItem('CLARA_FEEDBACK');
      const redirectPage = feedback === null ? 'PageFeedback' : 'PageComplete';
      this.page = complete ? redirectPage : page;

      this.checkCompletion();
    },
    updateScores(data) {
      this.phases[data.phase - 1] = data;
      window.sessionStorage.setItem('CLARA_PHASES', JSON.stringify(this.phases));
    },
    isComplete() {
      for (let i = 0; i < this.phases.length; i += 1) {
        if (!this.phases[i] || !this.phases[i].pass) {
          return false;
        }
      }
      return true;
    },

    onAction(event) {
      if (event.type in this.actionHandlers) {
        this.actionHandlers[event.type](event.data);
      }
    },
    setPage(page) {
      this.page = page;
    },
    navigate(url) {
      window.location = url;
    },
    playVideo(data) {
      this.video = data;
      this.page = 'PageVideo';
    },
    closeVideo(data) {
      window.sessionStorage.setItem('CLARA_COMPLETED', JSON.stringify({ index: this.video.phase - 1 }));
      this.updateScores(data);
      this.video = null;
      this.page = 'PageMenu';
      this.updateScores(data);
      this.checkCompletion();
    },
    closePopup() {
      const complete = this.isComplete();
      this.page = complete ? 'PageComplete' : 'PageMenu';
    },
    generateDateTime() {
      const date = new Date();
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      var hours = date.getHours();
      var minutes = date.getMinutes();

      day = day < 10 ? `0${day}` : day;
      month = month < 10 ? `0${month}` : month;
      hours = hours < 10 ? `0${hours}` : hours;
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      return {
        date: `${day}/${month}/${year}`,
        time: `${hours}:${minutes}`
      };
    },
    generateCert(data) {
      const now = this.generateDateTime();
      const url = `${this.config.certificate}?name=${data.name}&score=${data.score}&date=${now.date}&time=${now.time}`;
      window.open(url,'_blank');
      // Log rating
      window.sessionStorage.removeItem('CLARA_PHASES');
      this.trackEvent({
        type: 'certificate-download',
        module: 'menu',
        id: 'download',
      });
      this.resetPhases();

      this.page = 'PageSplash';
    },
    submitFeedback(data) {
      this.trackEvent({
        type: 'feedback',
        module: 'feedback',
        id: data.join(','),
      });
    },
    resetPhases() {
      this.phases = [];
      for (let i = 0; i < 4; i += 1) {
        this.phases.push({
          stage: i + 1,
          pass: false,
          score: 0,
        });
      }
    },
    trackEvent(data) {
      console.log({ // window._mtm.push({
        'event': `claras_story.module.${data.type}`,
        'element': `claras_story.module.${data.module}`,
        'identifier': data.id,
      });
      if (this.config.production) {
        this.logEvent(data);
      }
    },
    logEvent(data) {
      window._mtm.push({
        'event': `claras_story.module.${data.type}`,
        'element': `claras_story.module.${data.module}`,
        'identifier': data.id,
      });
    },
    trackCompletion(index) {
      const module = this.config.items[index].action.tracking;
      const data = {
        type: this.phases[index].pass ? 'passed' : 'failed',
        module: module.id,
        id: `${module.id}_score: ${this.phases[index].score}`,
      };
      this.trackEvent(data);
    },
    checkCompletion() {
      let complete = window.sessionStorage.getItem('CLARA_COMPLETED');
      if (complete) {
        window.sessionStorage.removeItem('CLARA_COMPLETED');
        complete = JSON.parse(complete);
        this.trackCompletion(complete.index);
      }
    },
  },
  mounted() {
    this.getConfig();
  },
}
</script>

<style lang="scss">
@import "./scss/index";
</style>
